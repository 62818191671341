import React, { useMemo, useState, useRef } from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  Collapse,
} from '@chakra-ui/react';
import {
  AccordionPalette,
  Button,
  Card,
  CardPalette,
  Divider,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { ReactComponent as Doctor } from '../../assets/doctor.svg';
import { ReactComponent as Tablet } from '../../assets/tablet.svg';
import { ReactComponent as Warning } from '../../assets/warning-triangle.svg';
import {
  OrderTimeline,
  OrderTimelineProps,
  StageStyle,
} from '@customer-frontend/treatment';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import {
  Maybe,
  OfferingPlanPageOrderTimelineItemFragment,
  OfferingPlanQuery,
  OfferingPlanQueryVariables,
  ProblemType,
  PsgTimelineFragment,
} from '@customer-frontend/graphql-types';
import {
  usePurchaseCardContent,
  formatCentsToCurrency,
} from '@customer-frontend/order';
import { getConfig } from '@customer-frontend/config';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { getZendeskRequestUrl, useTitle } from '@customer-frontend/utils';
import { DelayModal } from './delay-modal';
import clsx from 'clsx';
import { useThemeExtension } from '@eucalyptusvc/design-system/src/theme/shared';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { Logger } from '@customer-frontend/logger';

type OrderTimelineType = NonNullable<
  NonNullable<
    NonNullable<OfferingPlanQuery['profile']>['purchases']
  >[number]['orderTimeline']
>;

export type OrderTimelineItemPalette = Record<
  'next' | 'paid' | 'fulfilled' | 'strikethroughColor',
  string
>;

export type ProblemTypeIcons = Partial<
  Record<
    ProblemType | 'default',
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  >
>;

function getTitleForOrderTimelineItem(
  otli: OfferingPlanPageOrderTimelineItemFragment,
): React.ReactNode {
  const config = getConfig();

  if (otli.status === 'UPCOMING') {
    return (
      <FormattedMessage
        defaultMessage="Payment due:{br}<a>{date}</a>"
        description="Label showing when an upcoming order will be processed"
        values={{
          date: formatDate(mapBrandToAdaptersBrand(config.brand), otli.date, {
            dateStyle: 'medium',
          }),
          a: (chunks) => <time dateTime={otli.date}>{chunks}</time>,
          br: <br />,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage="Order paid:{br}<a>{date}</a>"
      description="Label showing when the order was paid by the user"
      values={{
        date: formatDate(mapBrandToAdaptersBrand(config.brand), otli.date, {
          dateStyle: 'medium',
        }),
        a: (chunks) => <time dateTime={otli.date}>{chunks}</time>,
        br: <br />,
      }}
    />
  );
}

function getOrderNumberAdornmentForOrderTimelineItem(
  otli: OfferingPlanPageOrderTimelineItemFragment,
  nextOrder: Maybe<OfferingPlanPageOrderTimelineItemFragment>,
  palette: OrderTimelineItemPalette,
): React.ReactNode | undefined {
  const className = 'px-3 py-1 h-6 text-xs font-semibold rounded';
  if (otli.status === 'FULFILLED') {
    return (
      <div className={clsx(className, palette.fulfilled)}>
        <FormattedMessage
          defaultMessage="Fulfilled"
          description="Label in the timeline component showing that the order has been fulfilled"
        />
      </div>
    );
  }
}

function getStageForOrderTimelineItem(
  otli: OfferingPlanPageOrderTimelineItemFragment,
  orders: OfferingPlanPageOrderTimelineItemFragment[],
  nextOrder: Maybe<OfferingPlanPageOrderTimelineItemFragment>,
): StageStyle {
  const i = orders.indexOf(otli);
  if (otli.status === 'FULFILLED') {
    return 'circleFilled';
  }
  if (
    (orders[i + 1] && orders[i + 1]?.id === nextOrder?.id) ||
    (otli.id === nextOrder?.id && i === 0)
  ) {
    return 'circleStrong';
  }
  return 'circle';
}

function getPrimaryLabelAdornmentForOrderTimelineItem(
  otli: OfferingPlanPageOrderTimelineItemFragment,
): React.ReactElement | null {
  for (const p of otli.products ?? []) {
    for (const m of p.erxMedicines) {
      const itemForm = m.itemForm?.toLowerCase();
      if (itemForm?.includes('tablet')) {
        return <Tablet className="h-4 w-4" />;
      }
    }
  }
  return null;
}

function useOrderTimelineProps({
  orderTimelineItems,
  nextOrder,
  shouldShowAllItems,
  palette,
}: {
  orderTimelineItems?: Maybe<OfferingPlanPageOrderTimelineItemFragment[]>;
  nextOrder?: Maybe<OfferingPlanPageOrderTimelineItemFragment>;
  shouldShowAllItems?: boolean;
  palette: OrderTimelineItemPalette;
}): OrderTimelineProps {
  if (!orderTimelineItems?.length) {
    return { orders: [] };
  }

  let filteredOrders: OfferingPlanPageOrderTimelineItemFragment[] =
    orderTimelineItems;
  let currentOrderIndex = 0;
  if (!shouldShowAllItems) {
    currentOrderIndex = orderTimelineItems.findIndex(
      (o, i) =>
        (orderTimelineItems[i + 1] &&
          orderTimelineItems[i + 1]?.id === nextOrder?.id) ||
        (o.id === nextOrder?.id && i === 0),
    );

    filteredOrders =
      currentOrderIndex !== -1
        ? orderTimelineItems.slice(currentOrderIndex)
        : [];
  }

  return {
    orders: [
      ...filteredOrders.map<OrderTimelineProps['orders'][0]>((otli) => {
        return {
          id: otli.id,
          title: (
            <Typography size="sm" isBold element="span">
              {getTitleForOrderTimelineItem(otli)}
            </Typography>
          ),
          primaryLabelAdornment:
            getPrimaryLabelAdornmentForOrderTimelineItem(otli),
          primaryLabel: (
            <div>
              {otli.products
                ?.slice(0)
                ?.sort((a, b) => {
                  return a.productType === 'RX' &&
                    a.productType !== b.productType
                    ? -1
                    : 0;
                })
                ?.map((p) => (
                  <div key={p.id}>{p.friendlyName || p.name}</div>
                ))}
            </div>
          ),
          orderNumberAdornment: getOrderNumberAdornmentForOrderTimelineItem(
            otli,
            nextOrder,
            palette,
          ),
          price: formatCentsToCurrency(otli.totalAmount),
          stage: getStageForOrderTimelineItem(
            otli,
            orderTimelineItems,
            nextOrder,
          ),
        };
      }),
      {
        id: 'doctor-check-in',
        title: (
          <FormattedMessage
            defaultMessage="Practitioner checkin"
            description="Title in the timeline component showing a practitioners checkin after all orders are processed"
          />
        ),
        primaryLabel: (
          <div className="flex flex-row gap-x-2">
            <Doctor />
            <div>
              <FormattedMessage
                defaultMessage="Your {isGb, select, true {prescriber} other {practitioner}} will check in with you once you have completed your treatment plan."
                values={{ isGb: getConfig().countryCode === 'GB' }}
              />
            </div>
          </div>
        ),
        stage: nextOrder === null ? 'circleStrong' : 'circle',
      },
    ],
  };
}

// todo: https://linear.app/eucalyptus/issue/TXN-2243/[customer-frontend]-remove-ff-and-old-timeline-code
// move the contents of this function into the OfferingPlanPage component when old timeline is removed
function getOrderTimelinePropsForPsgTimeline({
  syncGroups,
  shouldShowAllItems,
  palette,
  logger,
}: {
  syncGroups: PsgTimelineFragment[];
  shouldShowAllItems?: boolean;
  palette: OrderTimelineItemPalette;
  logger: Logger;
}): OrderTimelineProps {
  let items: NonNullable<PsgTimelineFragment['timeline']> = [];
  for (const psg of syncGroups ?? []) {
    const rxCsc = psg.sequenceContexts?.find(
      (sc) => sc.sequence?.__typename === 'PrescribableSequence',
    );
    if (!rxCsc) {
      continue;
    }

    if (!psg.timeline) {
      logger.error('expected to find timeline for rx csc', {
        purchase_sync_group_id: psg.id,
        sequence_context_id: rxCsc.id,
      });
      break;
    }

    items = psg.timeline;
    break;
  }

  if (items.length === 0) {
    return {
      orders: [],
    };
  }

  let currentOrderIdx = -1;

  const nextOrder = items.find((item) => item.status === 'UPCOMING');

  for (let idx = 0; idx < items.length; idx += 1) {
    const item = items[idx];
    if (item.status === 'PLACED') {
      currentOrderIdx = idx;
      break;
    }

    if (item.status === 'UPCOMING') {
      currentOrderIdx = idx;
    }

    if (currentOrderIdx === -1 && item.status === 'FULFILLED') {
      currentOrderIdx = idx;
    }
  }

  const visibleTimelineItems = items.slice(
    shouldShowAllItems ? 0 : currentOrderIdx,
  );

  const orders: OrderTimelineProps['orders'] = [];

  const config = getConfig();

  for (let idx = 0; idx < visibleTimelineItems.length; idx += 1) {
    const tli = visibleTimelineItems[idx];

    const title = (
      <div className="flex flex-col sm:flex-row sm:space-x-1">
        <div>
          {tli.status === 'UPCOMING' ? (
            <FormattedMessage
              defaultMessage="Payment due:"
              description="Label showing when an upcoming order will be processed"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Order paid:"
              description="Label showing when the order was paid by the user"
            />
          )}
        </div>
        <a>
          <time dateTime={tli.date}>
            {formatDate(mapBrandToAdaptersBrand(config.brand), tli.date, {
              dateStyle: 'medium',
            })}
          </time>
        </a>
      </div>
    );

    const primaryLabel = (
      <div>
        <div className="gap-2">
          {tli.products?.map((p) => (
            <Typography size="paragraph" key={p.id}>
              {p.displayName}
            </Typography>
          ))}
        </div>
        {tli.addonProducts && tli.addonProducts.length > 0 && (
          <div className="gap-2">
            {tli.addonProducts.map((p) => (
              <div key={p.id}>{p.displayName}</div>
            ))}
          </div>
        )}
      </div>
    );

    let strikethroughPrice: React.ReactNode | undefined;

    const discount =
      tli.preDiscountTotalAmount.baseUnitAmount -
      tli.totalAmount.baseUnitAmount;

    if (discount !== 0) {
      strikethroughPrice = (
        <Typography size="medium-paragraph">
          <span className={clsx(palette.strikethroughColor, 'line-through')}>
            {formatCentsToCurrency(discount, {
              omitZeroDecimals: true,
            })}
          </span>
        </Typography>
      );
    }

    const nextTimelineItem = visibleTimelineItems.at(idx + 1);

    let stage: StageStyle = 'circle';
    if (tli.status === 'FULFILLED') {
      stage = 'circleFilled';
    }

    if (
      (nextTimelineItem && nextTimelineItem.id === nextOrder?.id) ||
      (tli.id === nextOrder?.id && idx === 0)
    ) {
      stage = 'circleStrong';
    }

    orders.push({
      id: tli.id,
      title,
      stage,
      primaryLabel,
      price: formatCentsToCurrency(tli.totalAmount.baseUnitAmount, {
        omitZeroDecimals: true,
      }),
      strikethroughPrice,
    });
  }

  orders.push({
    id: 'doctor-check-in',
    title: (
      <FormattedMessage
        defaultMessage="Practitioner checkin"
        description="Title in the timeline component showing a practitioners checkin after all orders are processed"
      />
    ),
    primaryLabel: (
      <div className="flex flex-row gap-x-2">
        <Doctor />
        <div>
          <FormattedMessage
            defaultMessage="Your {isGb, select, true {prescriber} other {practitioner}} will check in with you once you have completed your treatment plan."
            values={{ isGb: getConfig().countryCode === 'GB' }}
          />
        </div>
      </div>
    ),
    stage: nextOrder === null ? 'circleStrong' : 'circle',
  });

  return {
    orders,
  };
}

export type OfferingPlanPageProps = {
  profileRoute: string;
  switchRoute: (purchaseId: string) => string;
  palette: {
    orderTimelineItem: OrderTimelineItemPalette;
    refillCard?: CardPalette;
  };
  logger: Logger;
};

export function OfferingPlanPage({
  profileRoute,
  switchRoute,
  palette,
  logger,
}: OfferingPlanPageProps): React.ReactElement {
  const history = useHistory();
  const config = getConfig();
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const urlParams = new URLSearchParams(window.location.search);
  const focusOnDelay = urlParams.get('focus') === 'delay';
  const initialJumpCompleted = useRef(false);

  const [upcomingOrderToDelay, setUpcomingOrderToDelay] = useState<
    { syncGroupId: string; date: string; delayableUntil: string } | undefined
  >();
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Treatment plan',
      description: 'Page title for the Treatment plan page',
    }),
  );

  const psgTimelineEnabled = useFeatureFlagBoolean(
    'FF_PLAN_PAGE_PSG_TIMELINE_ENABLED',
  );

  const { data, loading, refetch } = useQuery<
    OfferingPlanQuery,
    OfferingPlanQueryVariables
  >(
    gql`
      ${usePurchaseCardContent.fragment}
      fragment OfferingPlanPageOrderTimelineItem on OrderTimelineItem {
        id
        status
        date
        totalAmount
        products {
          id
          name
          friendlyName
          productType
          photo {
            id
            url
          }
          erxMedicines {
            id
            itemForm
          }
        }
        sequenceContext {
          id
          status
        }
      }

      fragment PsgTimeline on PurchaseSyncGroup {
        id
        sequenceContexts {
          id
          sequence {
            id
            __typename
          }
        }
        timeline {
          id
          products {
            id
            displayName
          }
          addonProducts {
            id
            displayName
          }
          date
          status
          preDiscountTotalAmount {
            id
            baseUnitAmount
          }
          totalAmount {
            id
            baseUnitAmount
          }
        }
      }

      query OfferingPlan($psgTimelineEnabled: Boolean!) {
        profile {
          id
          email
          purchases {
            ...UsePurchaseCardContentPurchase
            id
            status
            problemType
            contexts {
              id
              status
              consultations {
                id
                type
                status
              }
              sequence {
                __typename
                id
                externalLinks {
                  id
                  text
                  url
                }
                products {
                  id
                  name
                  friendlyName
                  productType
                  photo {
                    id
                    url
                  }
                }
              }
            }
            offering {
              id
              status
              friendlyName
              description
              advertisedName
            }
            nextOrder {
              ...OfferingPlanPageOrderTimelineItem
            }
            orderTimeline {
              ...OfferingPlanPageOrderTimelineItem
            }
            canBeSwitched
            syncGroups {
              id
              nextOrderDue
              status
              actions {
                id
                ... on DelayPurchaseSyncGroupAction {
                  delayableUntil
                }
              }
              sequenceContexts {
                id
                sequence {
                  id
                }
              }
              ...PsgTimeline @include(if: $psgTimelineEnabled)
            }
          }
        }
      }
    `,
    {
      // This is used to ensure customers won't interact with the page
      // until we have the results of the delay mutation.
      notifyOnNetworkStatusChange: true,
      variables: {
        psgTimelineEnabled,
      },
    },
  );

  const [isTreatmentScheduleOpen, setIsTreatmentScheduleOpen] = useState(false);

  const purchase = data?.profile?.purchases?.find((p) => p.id === purchaseId);

  const syncGroupBySequenceContextId = useMemo(() => {
    const m = new Map<
      string,
      NonNullable<
        NonNullable<
          NonNullable<OfferingPlanQuery['profile']>['purchases']
        >[number]['syncGroups']
      >[number]
    >();

    for (const syncGroup of purchase?.syncGroups ?? []) {
      for (const sc of syncGroup.sequenceContexts ?? []) {
        m.set(sc.id, syncGroup);
      }
    }

    return m;
  }, [purchase]);

  const orderTimelineProps = useOrderTimelineProps({
    orderTimelineItems: purchase?.orderTimeline,
    nextOrder: purchase?.nextOrder,
    shouldShowAllItems: isTreatmentScheduleOpen,
    palette: palette.orderTimelineItem,
  });

  const psgTimelineProps = getOrderTimelinePropsForPsgTimeline({
    palette: palette.orderTimelineItem,
    syncGroups: purchase?.syncGroups ?? [],
    shouldShowAllItems: isTreatmentScheduleOpen,
    logger,
  });

  const timelineToRender =
    psgTimelineEnabled && psgTimelineProps.orders.length > 0
      ? psgTimelineProps
      : orderTimelineProps;

  // Each purchase sync group is a separate entity that can be delayed.
  // Hence we want an upcoming order displayed for each of these.
  const upcomingOrders = useMemo(() => {
    const internalUpcomingOrders: OrderTimelineType = [];
    if (purchase?.orderTimeline) {
      const upcomingOrderTimeline = purchase.orderTimeline.filter(
        (ot) => ot.status === 'UPCOMING',
      );
      const purchaseSyncGroupIds = new Set<string>(
        upcomingOrderTimeline
          .filter((uot) => uot.sequenceContext?.status === 'ACTIVE')
          .map(
            (uot) =>
              syncGroupBySequenceContextId.get(uot.sequenceContext?.id ?? '')
                ?.id,
          )
          .filter((psgid): psgid is string => !!psgid),
      );
      for (const purchaseSyncGroupId of Array.from(purchaseSyncGroupIds)) {
        const firstOrderForPurchaseSyncGroupId = upcomingOrderTimeline.find(
          (ot) =>
            syncGroupBySequenceContextId.get(ot.sequenceContext?.id ?? '')
              ?.id === purchaseSyncGroupId,
        );
        if (firstOrderForPurchaseSyncGroupId) {
          internalUpcomingOrders.push(firstOrderForPurchaseSyncGroupId);
        }
      }
    }
    return internalUpcomingOrders;
  }, [purchase, syncGroupBySequenceContextId]);

  const ordersAccordionThemeStyle = useThemeExtension<AccordionPalette>(
    'accordion.defaultStyle',
    'default',
  );

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (
    !purchase ||
    !purchase.contexts?.length ||
    purchase.contexts.every((c) => c.status !== 'ACTIVE')
  ) {
    return <Redirect to={profileRoute} />;
  }

  const refillNowUrl = getZendeskRequestUrl({
    params: config.weightRefillNowZendeskParams,
    email: data?.profile?.email,
  });
  const rescheduleUrl = getZendeskRequestUrl({
    params: config.weightRescheduleZendeskParams,
    email: data?.profile?.email,
  });
  const resumeNowUrl = getZendeskRequestUrl({
    params: config.weightResumeNowZendeskParams,
    email: data?.profile?.email,
  });

  const hasActiveContext = purchase.contexts.some(
    (c) =>
      c.status === 'ACTIVE' &&
      c.sequence?.products?.some((p) => ['OTC', 'RX'].includes(p.productType)),
  );

  const externalLinks = purchase.contexts.flatMap(
    (c) => c.sequence?.externalLinks ?? [],
  );

  return (
    <article className="space-y-8">
      <div className="space-y-4">
        <Typography isBold size="lg">
          <FormattedMessage defaultMessage="Your plan" />
        </Typography>

        {purchase.problemType && (
          <Card>
            <div className="flex flex-row justify-between items-center gap-2 mb-3">
              <div className="flex flex-col gap-2">
                {purchase.canBeSwitched && (
                  <>
                    <div className="flex justify-between">
                      {purchase.offering?.advertisedName && (
                        <div className="mb-3">
                          <Typography isBold size="sm">
                            {purchase.offering.advertisedName}
                          </Typography>
                        </div>
                      )}
                      <Button
                        onClick={() => history.push(switchRoute(purchaseId))}
                        size="sm"
                        palette="alternate"
                        level="secondary"
                      >
                        <FormattedMessage defaultMessage="Change plan" />
                      </Button>
                    </div>
                    {purchase.offering?.friendlyName && (
                      <Typography size="paragraph">
                        {purchase.offering.friendlyName}
                      </Typography>
                    )}
                  </>
                )}
                {!purchase.canBeSwitched && (
                  <div className="flex justify-between">
                    {purchase.offering?.friendlyName && (
                      <div className="mb-3">
                        <Typography isBold size="sm">
                          {purchase.offering.friendlyName}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-4">
              {externalLinks.length > 0 && (
                <>
                  <Divider variant="separator" />

                  <div className="flex flex-col gap-2 pb-2">
                    {externalLinks.map((link) => (
                      <a
                        key={link.id}
                        href={link.url}
                        className="text-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography size="medium-paragraph">
                          {link.text}
                        </Typography>
                      </a>
                    ))}
                  </div>
                </>
              )}

              {hasActiveContext && timelineToRender.orders.length > 0 && (
                <ChakraAccordion
                  allowToggle
                  variant={ordersAccordionThemeStyle}
                  allowMultiple={true}
                >
                  <AccordionItem
                    style={{
                      borderBottom: '0',
                      paddingBottom: '0',
                    }}
                  >
                    {({ isExpanded }) => {
                      return (
                        <>
                          <Typography size="medium-paragraph" isBold>
                            <FormattedMessage defaultMessage="Order schedule" />
                          </Typography>
                          <div>
                            <Collapse
                              animateOpacity
                              startingHeight={280}
                              in={isExpanded}
                            >
                              <div className="pt-4 overflow-hidden">
                                <OrderTimeline {...timelineToRender} />
                              </div>
                            </Collapse>
                          </div>

                          <AccordionButton>
                            <div className="mt-3 w-full">
                              <Button
                                level="secondary"
                                isFullWidth
                                onClick={() =>
                                  setIsTreatmentScheduleOpen(!isExpanded)
                                }
                              >
                                {isExpanded && (
                                  <FormattedMessage
                                    defaultMessage="Hide full order schedule"
                                    description="Button text to fold an according hiding some of the customers treatment schedule"
                                  />
                                )}
                                {!isExpanded && (
                                  <FormattedMessage
                                    defaultMessage="See full order schedule"
                                    description="Button text to unfold an according showing all upcoming orders for a patients treatment schedule"
                                  />
                                )}
                              </Button>
                            </div>
                          </AccordionButton>
                        </>
                      );
                    }}
                  </AccordionItem>
                </ChakraAccordion>
              )}

              {!hasActiveContext && (
                <Typography size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="To resume your treatment, please contact us <a>here</a>."
                    description="Call to action to contact PX if sequence context is paused"
                    values={{
                      a: (chunks) => {
                        return (
                          <a
                            className="underline cursor"
                            href={resumeNowUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {chunks}
                          </a>
                        );
                      },
                    }}
                  />
                </Typography>
              )}
            </div>
          </Card>
        )}
      </div>

      {upcomingOrders.length > 0 && (
        <div
          className="space-y-4"
          ref={(e) => {
            if (focusOnDelay && e && !initialJumpCompleted.current) {
              e.scrollIntoView();
              initialJumpCompleted.current = true;
            }
          }}
        >
          <Typography isBold size="lg">
            <FormattedMessage
              defaultMessage="Your upcoming orders"
              description="Title for upcoming orders on treatment plan page"
            />
          </Typography>

          {upcomingOrders.map((upcomingOrder) => {
            const sequenceContextId = upcomingOrder.sequenceContext?.id;
            const date =
              upcomingOrder.date !== undefined && upcomingOrder.date !== null
                ? upcomingOrder.date
                : undefined;

            const syncGroup = syncGroupBySequenceContextId.get(
              sequenceContextId ?? '',
            );

            const delayAction = syncGroup?.actions?.find(
              (action) => action.__typename === 'DelayPurchaseSyncGroupAction',
            );

            return (
              <Card key={upcomingOrder.id}>
                {upcomingOrder.products && (
                  <div className="space-y-4">
                    <Typography isBold size="xs">
                      <FormattedMessage
                        defaultMessage="Order contains:"
                        description="Title for an upcoming order on treatment plan page"
                      />
                    </Typography>

                    {upcomingOrder.products.map((product) => (
                      <div
                        key={product.id}
                        className="flex justify-between items-center"
                      >
                        <div className="flex items-center space-x-2">
                          {product.photo?.url && (
                            <img
                              src={product.photo.url}
                              className="w-14 h-14 rounded"
                            />
                          )}
                          <Typography size="paragraph">
                            {product.friendlyName}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <Divider variant="separator" />

                <div className="space-y-4">
                  <div className="flex justify-between">
                    <Typography isBold size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="Next order total"
                        description="Title for amount due in upcoming order"
                      />
                    </Typography>
                    <Typography isBold size="medium-paragraph">
                      {formatCentsToCurrency(upcomingOrder.totalAmount)}
                    </Typography>
                  </div>

                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage="This will automatically be deducted from your default payment method on {date}."
                      values={{
                        date: formatDate(
                          mapBrandToAdaptersBrand(config.brand),
                          upcomingOrder.date,
                          {
                            dateStyle: 'short',
                          },
                        ),
                      }}
                      description="Explanation of when amount will be charged"
                    />
                  </Typography>

                  {!delayAction && (
                    <Card palette={palette.refillCard}>
                      <div className="space-y-2">
                        <div className="flex space-x-2 items-center">
                          <Warning className="h-4 w-4" />
                          <Typography isBold size="paragraph">
                            <FormattedMessage
                              defaultMessage="Need to reschedule your order?"
                              description="Title for requesting a reschedule call to action"
                            />
                          </Typography>
                        </div>

                        <Typography size="paragraph">
                          <FormattedMessage
                            defaultMessage="If you require your order to be rescheduled, please request the new order date <a>here</a>."
                            description="Body for requesting a reschedule call to action"
                            values={{
                              a: (chunks) => {
                                return (
                                  <a
                                    className="underline cursor"
                                    href={rescheduleUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {chunks}
                                  </a>
                                );
                              },
                            }}
                          />
                        </Typography>
                      </div>
                    </Card>
                  )}

                  <Card palette={palette.refillCard}>
                    <div className="space-y-2">
                      <div className="flex space-x-2 items-center">
                        <Warning className="h-4 w-4" />
                        <Typography isBold size="paragraph">
                          <FormattedMessage
                            defaultMessage="Need a refill now?"
                            description="Title for requesting a refill call to action"
                          />
                        </Typography>
                      </div>

                      <Typography size="paragraph">
                        <FormattedMessage
                          defaultMessage="If you require a refill now, please request an early refill <a>here</a>."
                          description="Body for requesting a refill call to action"
                          values={{
                            a: (chunks) => {
                              return (
                                <a
                                  className="underline cursor"
                                  href={refillNowUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {chunks}
                                </a>
                              );
                            },
                          }}
                        />
                      </Typography>
                    </div>
                  </Card>

                  {syncGroup && date && delayAction && (
                    <Button
                      isFullWidth
                      level="secondary"
                      onClick={() => {
                        setUpcomingOrderToDelay({
                          syncGroupId: syncGroup.id,
                          date,
                          delayableUntil: delayAction.delayableUntil,
                        });
                      }}
                    >
                      <Typography size="large-paragraph" isBold>
                        <FormattedMessage
                          defaultMessage="Delay"
                          description="Button text for delaying an upcoming order"
                        />
                      </Typography>
                    </Button>
                  )}
                </div>
              </Card>
            );
          })}
        </div>
      )}

      {!!upcomingOrderToDelay && (
        <DelayModal
          onClose={() => setUpcomingOrderToDelay(undefined)}
          upcomingOrder={upcomingOrderToDelay}
          onDelaySuccess={refetch}
          onDelayError={refetch}
        />
      )}
    </article>
  );
}
